<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mx-1 mb-3"
      vs-justify="flex-start"
      vs-align="flex-start"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          type="date"
          class="mx-1"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          type="date"
          class="mx-1"
          size="small"
          label="Data Até"
          v-model="filtro.dataAte"
        ></vs-input>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Tipo Pessoa</label
          >
          <br />
          <el-select
            filterable
            clearable
            size="small"
            :popper-append-to-body="false"
            v-model="filtro.tipo_pessoa"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="tipo in tipo_pessoa"
              :value="tipo.value"
              :label="tipo.nome"
              :key="tipo.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Forma de pagamento</label
          >
          <br />
          <el-select
            filterable
            clearable
            size="small"
            :popper-append-to-body="false"
            v-model="filtro.forma_pagamento"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="tipo in formasPagamento"
              :value="tipo.id"
              :label="tipo.nome"
              :key="tipo.id"
            ></el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="close()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="flex-start">
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #000a12">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    PARA REALIZAR: {{ paraCobrar.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <perfect-scrollbar
            class="scroll-cobranca"
            @ps-y-reach-end="showMoreCobrancaParaCobrar()"
          >
            <div id="paraCobrar" class="vs-con-loading__container">
              <div v-if="!paraCobrarLista.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>
              <div v-for="data in paraCobrarLista" :key="data.id" class="mx-2">
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="12">
                            <vs-col
                              vs-w="7"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              class="mt-1"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <vs-col
                              vs-w="5"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <Badge
                                :text="
                                  data.id_status != 3
                                    ? data.status
                                      ? data.status
                                      : 'Pendente'
                                    : 'Quit. Parcial.'
                                "
                                :color="data.badge"
                              ></Badge>
                              <Badge
                                :text="data.diasVencidos + ' dias'"
                                color="danger"
                              ></Badge>
                            </vs-col>
                            <div
                              class="w-full"
                              @click="
                                (contaSelecionada = data), (iniciarModal = true)
                              "
                            >
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span style="item-description truncate text-sm">
                                  Nota Ṇ°
                                  <b>{{ data.numero }}</b> -Conta N°
                                  <b>{{ data.id }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  <b
                                    ><a
                                      target="_blank"
                                      :href="'/contrato?id=' + data.id_contrato"
                                      style="color: darkblue; cursor: pointer"
                                      >{{ data.id_contrato }}&nbsp;
                                    </a>
                                  </b>
                                  &nbsp;-&nbsp;Valor:<b>{{
                                    $currency(data.valor)
                                  }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-sm">
                                  Vencimento:
                                  <b>{{ data.vencimento }}</b>
                                </span>
                              </vs-col>
                            </div>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #00bba2">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    SUCEDIDOS: {{ sucedidos.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <perfect-scrollbar
            class="scroll-cobranca"
            @ps-y-reach-end="showMoreCobrancaSucedidos()"
          >
            <div id="sucedidos" class="vs-con-loading__container">
              <div v-if="!sucedidosLista.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>
              <div v-for="data in sucedidosLista" :key="data.id" class="mx-2">
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="11">
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              class="mt-1"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span style="item-description truncate text-sm">
                                Nota Ṇ°
                                <b>{{ data.numero }}</b> - Conta N°
                                <b>{{ data.id }}</b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                <b
                                  ><a
                                    target="_blank"
                                    :href="'/contrato?id=' + data.id_contrato"
                                    style="color: darkblue; cursor: pointer"
                                    >{{ data.id_contrato }}&nbsp;
                                  </a>
                                </b>
                                &nbsp;-&nbsp;Valor:
                                <b>{{ $currency(data.valor) }}</b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Vencimento:
                                <b>{{ data.vencimento }}</b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Obs:
                                <b>{{ data.motivo }}</b>
                              </span>
                            </vs-col>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card style="background-color: #f44336">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    NÃO SUCEDIDOS: {{ naoSucedidos.length }}
                  </h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <perfect-scrollbar
            class="scroll-cobranca"
            @ps-y-reach-end="showMoreCobrancaNaoSucedidos()"
          >
            <div id="naoSucedidos" class="vs-con-loading__container">
              <div v-if="!naoSucedidosLista.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>
              <div
                v-for="data in naoSucedidosLista"
                :key="data.id"
                class="mx-2"
              >
                <vx-card :class="cardClass" class="mr-10 my-3">
                  <template slot="no-body">
                    <div class="item-details px-2">
                      <div class="my-1">
                        <div>
                          <vs-col vs-w="11">
                            <vs-col
                              vs-w="11"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                              class="mt-1"
                            >
                              <VisualizarCliente
                                @update="close()"
                                :id_cliente="data.id_cliente"
                                :nomeCliente="data.nomeCliente"
                              />
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span style="item-description truncate text-sm">
                                Nota Ṇ°
                                <b>{{ data.id_nota }}</b> - Conta N°
                                <b>{{ data.id }}</b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                <b
                                  ><a
                                    target="_blank"
                                    :href="'/contrato?id=' + data.id_contrato"
                                    style="color: darkblue; cursor: pointer"
                                    >{{ data.id_contrato }}&nbsp;
                                  </a>
                                </b>
                                &nbsp;-&nbsp;Valor:
                                <b>{{ $currency(data.valor) }}</b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Vencimento:
                                <b>{{ data.vencimento }}</b>
                              </span>
                            </vs-col>
                            <vs-col
                              vs-w="12"
                              vs-type="flex"
                              vs-justify="flex-start"
                              vs-align="flex-start"
                            >
                              <span class="item-description truncate text-sm">
                                Obs:
                                <b>{{ data.motivo }}</b>
                              </span>
                            </vs-col>
                          </vs-col>
                        </div>
                      </div>
                    </div>
                  </template>
                </vx-card>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </vs-col>
    </vs-row>
    <vx-card class="mt-1" no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="#fff"
            type="line"
            icon="icon-arrow-left"
            size="small"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
    <vs-popup
      :title="'COBRAR CONTA N° ' + contaSelecionada.id"
      :active.sync="iniciarModal"
    >
      <vs-row vs-type="flex" vs-align="center">
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <Confirmar @update="close()" :item="contaSelecionada"></Confirmar>
        </vs-col>
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <Declinar @update="close()" :item="contaSelecionada"></Declinar>
        </vs-col>
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-5"
        >
          <Contactar @update="close()" :item="contaSelecionada"></Contactar>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import Confirmar from "./components/confirmar";
import Declinar from "./components/declinar";
import Contactar from "./components/contactar";
import VisualizarCliente from "./../../../cliente/visualizar.vue";
export default {
  data() {
    return {
      tipo_pessoa: [
        { value: 1, nome: "PESSOA FÍSICA" },
        { value: 2, nome: "PESSOA JURÍDICA" },
      ],
      paraCobrar: [],
      paraCobrarLista: [],
      paraCobrarCount: 2,

      sucedidos: [],
      sucedidosLista: [],
      sucedidosCount: 2,

      naoSucedidos: [],
      naoSucedidosLista: [],
      naoSucedidosCount: 2,

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
      },
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
      },
      cardClass: "cobranca-card overflow-hidden",
      contaSelecionada: {},
      iniciarModal: false,

      formasPagamento: null,
    };
  },
  methods: {
    async voltar() {
      this.$router.push("/financeiro");
    },
    async getContasParaCobrar() {
      await this.$vs.loading({ container: "#paraCobrar", scale: 0.6 });
      try {
        this.paraCobrar = await this.$http.post(
          `getContasParaCobrar`,
          this.filtro
        );

        let hoje = this.$formartData.dataHoje();
        let dataVencimento;

        this.paraCobrar.forEach((conta) => {
          if (conta.data_vencimento) {
            dataVencimento = new Date(conta.data_vencimento);
            conta.diasVencidos = parseInt(
              Math.abs(new Date(hoje) - dataVencimento) / 86400000
            );
          }

          if (conta.id_status == 1 || !conta.id_status) {
            conta.badge = "success";
          } else if (conta.id_status == 2) {
            conta.badge = "danger";
          } else {
            conta.badge = "warning";
          }
          // }else {
          //   conta.badge = 'warning'
          // }
          return conta;
        });
        this.paraCobrarLista = this.paraCobrar.slice(0, 10);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#paraCobrar > .con-vs-loading");
      }
    },
    async getContasCobradas() {
      await this.$vs.loading({ container: "#sucedidos", scale: 0.6 });
      try {
        this.sucedidos = await this.$http.post(
          `getContasCobradas`,
          this.filtro
        );
        this.sucedidosLista = this.sucedidos.slice(0, 3);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#sucedidos > .con-vs-loading");
      }
    },
    async getContasNaoSucedidas() {
      await this.$vs.loading({ container: "#naoSucedidos", scale: 0.6 });
      try {
        this.naoSucedidos = await this.$http.post(
          `getContasNaoSucedidas`,
          this.filtro
        );
        this.naoSucedidosLista = this.naoSucedidos.slice(0, 3);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#naoSucedidos > .con-vs-loading");
      }
    },
    async showMoreCobrancaParaCobrar() {
      if (
        this.paraCobrar.length > 10 &&
        this.paraCobrar.length > this.paraCobrarLista.length
      ) {
        this.$vs.loading();
        await setTimeout(() => {
          this.paraCobrarLista = this.paraCobrar.slice(
            0,
            10 * this.paraCobrarCount
          );
          this.paraCobrarCount += 1;
          this.$vs.loading.close();
        }, 200);
      }
    },
    async showMoreCobrancaSucedidos() {
      if (
        this.sucedidos.length > 10 &&
        this.sucedidos.length > this.sucedidosLista.length
      ) {
        this.$vs.loading();
        await setTimeout(() => {
          this.sucedidosLista = this.sucedidos.slice(
            0,
            10 * this.sucedidosCount
          );
          this.sucedidosCount += 1;
          this.$vs.loading.close();
        }, 200);
      }
    },
    async showMoreCobrancaNaoSucedidos() {
      if (
        this.naoSucedidos.length > 10 &&
        this.naoSucedidos.length > this.naoSucedidosLista.length
      ) {
        this.$vs.loading();
        await setTimeout(() => {
          this.naoSucedidosLista = this.naoSucedidos.slice(
            0,
            10 * this.naoSucedidosCount
          );
          this.naoSucedidosCount += 1;
          this.$vs.loading.close();
        }, 200);
      }
    },

    async close() {
      this.iniciarModal = await false;
      await this.getContasParaCobrar();
      await this.getContasCobradas();
      await this.getContasNaoSucedidas();
    },

    async getFormasPagamento(){
      try{
        this.formasPagamento = await this.$http.get("formasPagamento")
      }catch(error){
        const erro = this.$httpErrors(error)
        this.$vs.notify(erro)
      }
    }
  },
  async mounted() {
    Promise.all([
      this.getFormasPagamento(),
      this.getContasParaCobrar(),
      this.getContasCobradas(),
      this.getContasNaoSucedidas()
    ])
  },
  components: {
    ...components,
    VisualizarCliente,
    Declinar,
    Contactar,
    Confirmar,
  },
};
</script>
<style scoped lang="scss">
.border {
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 1%;
}
.cobranca-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-cobranca {
  height: 70vh !important;
}
</style>
