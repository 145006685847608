<template>
  <div class="w-full">
    <vx-card
      class="contactar overflow-hidden"
      @click="
        (contatoSelected = {}),
          (contatosCliente = []),
          getContatos(),
          (osModal = true)
      "
    >
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon
                  class="img"
                  icon="contacts"
                  size="30px"
                  color="primary"
                ></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class
            >
              <span style="font-size: 20px" class="py-2">CONTACTAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'CONTATOS: ' + item.nomeCliente" :active.sync="osModal">
      <div id="contactar" class="vs-con-loading__container w-full">
        <div v-if="!contatosCliente.length > 0">
          <div class="con-colors mt-10 mb-20">
            <ul>
              <span
                class="px-3 pt-2"
                style="color: grey !important; font-weight: 1000 !important"
                ><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon
              ></span>
            </ul>
          </div>
        </div>
        <div v-else>
          <vs-row type="flex" class="mb-3" vs-justify="flex-start">
            <vs-col
              vs-type="flex"
              class="p-1"
              vs-justify="flex-start"
              vs-align="flex-start"
              :key="index"
              vs-w="6"
              v-for="(contato, index) in contatosCliente"
            >
              <vx-card
                no-shadow
                :class="
                  contato.id == contatoSelected.id
                    ? 'active-contato'
                    : 'contato'
                "
                @click="selectContato(contato)"
              >
                <vs-row class="mt-2">
                  <h5 style="color: black" class="font-semibold m-0 p-0">
                    {{ contato.nome }}
                  </h5>
                </vs-row>
                <vs-row class="mb-2">
                  <label
                    style="color: black; font-size: 14px"
                    class="m-0 p-0"
                    >{{ contato.contato }}</label
                  >
                </vs-row>
              </vx-card>
            </vs-col>
          </vs-row>
          <vs-button
            class="w-full mt-3"
            color="success"
            size="sm"
            type="relief"
            :disabled="
              contatoSelected.contato == null
            "
            @click="contactar()"
            >Contactar</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data () {
    return {
      osModal: false,
      contatosCliente: [],
      contatoSelected: { id: null, contato: null, nome: null }
    }
  },
  methods: {
    async getContatos () {
      await this.$vs.loading({ container: "#contactar", scale: 0.6 })
      try {
        this.contatosCliente = await this.$http.get(
          "contatoCliente/" + this.item.id_cliente
        )
        let contatoPrincipal = {}
        contatoPrincipal.nome = this.item.nomeCliente
        contatoPrincipal.contato = this.item.telefone
        contatoPrincipal.tipo = 2
        contatoPrincipal.id = 0
        this.contatosCliente.push(contatoPrincipal)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$vs.loading.close("#contactar > .con-vs-loading")
    },
    async selectContato (contato) {
      this.contatoSelected = Object.assign(contato)
    },
    async contactar(){
      this.contatoSelected.tipo == 1 ? this.contactarEmail() : this.contatoSelected.tipo == 2 ? this.contactarTelefone() : 
        this.$vs.notify({
            title: 'Erro',
            text: 'Erro ao contacat. Tente mais tarde ou contate o suporte',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
        })
    },
    async contactarTelefone () {
      let contato = this.contatoSelected.contato
      contato = "+55" + await this.$removerMascara.remove(this.contatoSelected.contato)

      await this.$vs.loading({ container: "#contactar", scale: 0.6 })
      try {
        let texto =
          "Olá, " +
          this.contatoSelected.nome +
          "! Gostaria de falar um pouco sobre a conta vencida no contrato " + this.item.id_contrato + " com a EMOPS. Teria um tempo para isso?"
        const res = await this.$http.post("whatsapp", {
          numero: contato,
          texto: texto
        })
        window.open(res)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
      await this.$vs.loading.close("#contactar > .con-vs-loading")
    },
    async contactarEmail(){
      let data = new Date(this.item.data_vencimento)
      let dataFormatada = `${(data.getDate() + 1).toString().padStart(2, '0')}/${(data.getMonth() + 1).toString().padStart(2, '0')}/${data.getFullYear()}`
      let body =
        `Olá, ${this.item.nomeCliente}.` +
        `Não identificamos em nosso sistema o(s) pagamento(s) do(s) documento(s) ${this.item.nota} `+
        `no valor de R$ ${this.item.valor} com data de vencimento ${dataFormatada}. ` + 
        `Estamos certos que o motivo do atraso foi causado por problemas circunstaciais e para evitar que multa e juros acumulem, `+
        `estamos enviando uma via atualizada para pagamento. Ficamos à disposição para negociar outro vencimento, caso prefira. ` +
        `Caso tenha sido efetuado o pagamento, favor desconsiderar.`+
        `At.te`
      
      let assunto = `Emops: Boleto ${this.item.nota} Pendente - ${this.item.nomeCliente}`
      let link = `mailto:${this.contatoSelected.contato}?subject=${assunto}&body=${body}`
      window.open(link)
    }
  },
};
</script>
<style scoped lang="scss">
.contactar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffca00;
    color: white;
    cursor: pointer;

    .img {
      transition-duration: 150ms;
      color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.contactarPop {
  background-color: #ffca00;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #ffca00;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.active-contato {
  background-color: #ffca00;
  transition: background 0.1s ease-in-out;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
}
.contato {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  background: #eeeeee;
  cursor: pointer;
  color: #ffca00 !important;
}
.contato:hover {
  transition: 0.1s;
  background: #fff;
  border-width: 1px;
  border-color: black;
  transform: translateY(-3px);
}
</style>
