<template>
  <div>
    <vx-card
      :class="
        $atividades.permissoes.includes(5)
          ? 'declinarHover overflow-hidden'
          : 'sem-autorizacao overflow-hidden'
      "
      @click="$atividades.permissoes.includes(5) ? (osModal = true) : ''"
    >
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class="p-5"
            >
              <span>
                <vs-icon
                  class="img"
                  icon="phone_missed"
                  size="30px"
                  color="#f44336"
                ></vs-icon>
              </span>
            </vs-col>
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              class
            >
              <span style="font-size: 20px" class="py-2">DECLINAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'COBRANÇA DA CONTA N° ' + item.id" :active.sync="osModal">
      <vs-row
        vs-type="flex"
        vs-align="center"
        id="declinar_cobrança"
        class="vs-con-loading__container"
      >
        <vs-col
          vs-w="12"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <vs-textarea
            v-model="obs"
            class="p-0 m-0 inputx w-full"
            name="obs"
            label="Observação da Cobrança"
            v-validate="'required'"
          />
        </vs-col>
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          class="p-3"
        >
          <vx-card class="declinar overflow-hidden" @click="declinarCobranca()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    class="p-1"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <span style="font-size: 20px">DECLINAR COBRANÇA</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      required: true,
    },
  },
  data() {
    return {
      osModal: false,
      obs: "",
    };
  },
  methods: {
    async declinarCobranca() {
      await this.$vs.loading({ container: "#declinar_cobrança", scale: 0.6 });
      try {
        const res = await this.$http.put(`alterarStatusConta/` + this.item.id, {
          motivo: this.obs,
          id_status: 6,
        });
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "atualizar",
        //   setor: "financeiro",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Declinio da Cobrança da Conta a Pagar N°" + res.id,
        // };
        // await this.$logger(logData);
        await this.logAssunto(res)
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.close();
        await this.$vs.loading.close("#declinar_cobrança > .con-vs-loading");
      }
    },
    async logAssunto (res) {
      await this.$http.post("assuntoCliente", {
        id_assuntos: 12,
        id_cliente: res.id_cliente,
        id_estagio: 3,
        status: 1,
        id_colaborador: window.localStorage.getItem("id"),
        id_contrato: res.id_contrato,
        obs: res.motivo,
      })
    },
    async close() {
      this.obs = "";
      this.osModal = await false;
    },
  },
};
</script>
<style lang="scss" scoped>
.declinarHover {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .img {
      transition-duration: 150ms;
      color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.declinar {
  background-color: #f44336;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #f44336;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
