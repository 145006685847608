<template>
  <div class="w-full">
    <vx-card :class="$atividades.permissoes.includes(5) ? 'conf overflow-hidden' : 'sem-autorizacao overflow-hidden'">
      <template slot="no-body">
        <div class="item-details px-2" @click="$atividades.permissoes.includes(5) ? osModal = true : ''">
          <div class="my-1">
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
              <span>
                <vs-icon class="img" icon="phone_forwarded" size="30px" color="success"></vs-icon>
              </span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class>
              <span style="font-size: 20px" class="py-2">CONFIRMAR</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>

    <vs-popup :title="'COBRANÇA DA CONTA N° ' + item.id " :active.sync="osModal">
      <vs-row vs-type="flex" vs-align="center" id="cobrar" class="vs-con-loading__container">
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-3">
          <vs-textarea v-model="obs" class="p-0 m-0 inputx w-full" name='obs' label="Observação da Cobrança" v-validate="'required'" />
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" vs-justify="center" vs-align="center" class="p-3">
          <vx-card class="confPop overflow-hidden" no-shadow @click="Confirmar()">
            <template slot="no-body">
              <div class="item-details px-2">
                <div class="my-1">
                  <vs-col vs-w="12" vs-type="flex" class="p-1" vs-justify="center" vs-align="center">
                    <span style="font-size: 20px">CONFIRMAR COBRANÇA</span>
                  </vs-col>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>

</template>
<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      osModal: false,
      obs: ''
    };
  },
  methods: {
    async Confirmar() {
      await this.$vs.loading({ container: "#cobrar", scale: 0.6 });
      try {
        const res = await this.$http.put(`alterarStatusConta/`+ this.item.id, {motivo: this.obs, id_status: 7});
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: 'atualizar',
        //   setor: 'financeiro',
        //   ip: window.localStorage.getItem("ip"),
        //   texto: 'Confirmação da Cobrança da Conta a Pagar N°' + res.id}
        // await this.$logger(logData)
        await this.logAssunto(res)
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.close()
        await this.$vs.loading.close("#cobrar > .con-vs-loading");
      }
    },
    async logAssunto(res) {
      await this.$http.post("assuntoCliente", {
        id_assuntos: 12,
        id_cliente: res.id_cliente,
        id_estagio: 3,
        status: 1,
        id_contrato: res.id_contrato,
        id_colaborador: window.localStorage.getItem("id"),
        obs: res.motivo,
      })
    },
    async close() {
      this.obs = ""
      this.osModal = await false;
    },
  }
};
</script>
<style lang="scss" scoped>

.conf {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .img {
      transition-duration: 150ms;
     color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.confPop {
  background-color: #00bba2;
  color: white;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #00bba2;
    color: white;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sem-autorizacao {
  background-color: #aaa;
  color: white;
  .img {
    color: white !important;
  }
}
</style>
